import React from 'react'
import what_img from "./assets/images/whatsapp.png";
const WhatsappComponent = () => {
    return (
        <div className='fixed bottom-[55px] right-[40px] sm:right-[40px] md:right-[55px] z-10'>
            <a href="https://wa.me/+919940580167" target='_blank' className=' relative z-10'>
                <img src={what_img} className='w-[40px] sm:w-[40px] md:w-[50px] lg:w-[60px]' alt="whatsapp_img" />
            </a>
            <div className="circle1 w-[40px] sm:w-[40px] md:w-[50px] lg:w-[60px]  h-[40px] sm:h-[40px] md:h-[50px] lg:h-[60px]" style={{ animationDelay: "-3s" }}></div>
            <div className="circle1 w-[40px] sm:w-[40px] md:w-[50px] lg:w-[60px]  h-[40px] sm:h-[40px] md:h-[50px] lg:h-[60px]" style={{ animationDelay: "-2s" }}></div>
            <div className="circle1 w-[40px] sm:w-[40px] md:w-[50px] lg:w-[60px]  h-[40px] sm:h-[40px] md:h-[50px] lg:h-[60px]" style={{ animationDelay: "-1s" }}></div>
            <div className="circle1 w-[40px] sm:w-[40px] md:w-[50px] lg:w-[60px]  h-[40px] sm:h-[40px] md:h-[50px] lg:h-[60px]" style={{ animationDelay: "-0s" }}></div>
        </div>
    )
}
export default WhatsappComponent