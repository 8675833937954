import React from 'react'

const WhyChooseUs = () => {
    return (
        <div className='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
            <div className='flex flex-col items-center gap-3'>
                <h4 className=" text-2xl font-bold font-medium primary-color text-center">
                    Why Choose Us?
                </h4>
            </div>
            <ul className='flex flex-col  px-16 leading-10'>
                <li><strong>Affordable and Transparent Pricing</strong>: With our "pay for only the ride" policy, you’re charged only for the distance traveled, making your journey economical and hassle-free.</li>
                <li><strong>Reliable Service</strong>: Count on us for timely pickups, comfortable rides, and professional service.</li>
                <li><strong>Customer-Centric Approach</strong>: Our focus is on delivering satisfaction every step of the way, from booking to destination.</li>
                <li><strong>Experienced Drivers</strong>: Enjoy a safe and smooth ride with our trained and knowledgeable drivers.</li>
            </ul>

        </div>
    )
}

export default WhyChooseUs