import React from "react";
import BookingForm from "../components/BookingForm";
import About from "../components/About";
import PopularRoutes from "../components/PopularRoutes";
import Cars from "../components/Cars";
import OurServices from "../components/Home/OurServices";
import OneWayServices from "../components/Home/OneWayServices";
import TopRoutes from "../components/Home/TopRoutes";
import PricePlan from "../components/Home/PricePlan";
import PopularOutstations from "../components/PopularOutstations";
import Chooseus from "../components/Home/Chooseus";
import Testimonial from "../components/Home/Testimonial";
import WhyChooseUs from "../components/Home/WhyChooseUs";


const Home = () => {
  return (
    <>

      <BookingForm />
      <Chooseus />
      <OurServices />
      {/* <About /> */}
      <PricePlan />
      <WhyChooseUs />
      <PopularOutstations />
      {/* <PopularRoutes /> */}
      {/* <OneWayServices /> */}

      <Testimonial />
      {/* <TopRoutes /> */}
    </>
  );
};

export default Home;
