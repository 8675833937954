import React from "react";
import img1 from "../../assets/images/corporate_ser.jpg";
import img2 from "../../assets/images/city_rides.jpg";
import img3 from "../../assets/images/airport-rides.jpg";
import img4 from "../../assets/images/intercity-travel.jpg";
const OurServices = () => {
  return (
    <div id='ourservices'>
      <div className="container py-5 mx-auto px-4 md:px-8 lg:px-16">
        <div className='flex flex-col items-center gap-3'>
          <h4 className=" text-2xl font-bold font-medium primary-color text-center">
            Our Services
          </h4>
          <center className='lg:w-1/2'>Discover the simplicity and convenience of using our drop taxi service. With just three easy steps, you can book a taxi, reach your destination, and enjoy a hassle-free travel experience. Read on to find out how it works!</center>

        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-3 mt-6'>
          <div className="flex flex-col gap-2  border-2 px-4 py-8 rounded-lg">
            <div>
              <img src={img4} className="rounded-lg h-[210px] w-full" />
            </div>
            <h4 className="font-bold text-center text-3xl">One-Way Fares Only</h4>
            <p className="text-center text-lg">Pay solely for your ride, no return charges.</p>
          </div>
          <div className="flex flex-col gap-2  border-2 px-4 py-8 rounded-lg">
            <div>
              <img src={img3} className="rounded-lg h-[210px] w-full" />
            </div>
            <h4 className="font-bold text-center text-3xl">24/7 Service</h4>
            <p className="text-center text-lg">We’re available round-the-clock to cater to your travel needs.</p>
          </div>
          <div className="flex flex-col gap-2  border-2 px-4 py-8 rounded-lg">
            <div>
              <img src={img2} className="rounded-lg h-[210px] w-full" />
            </div>
            <h4 className="font-bold text-center text-3xl">Professional Drivers
            </h4>
            <p className="text-center text-lg">Our team of licensed, experienced drivers ensures a safe journey.</p>
          </div>



        </div>
        <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  gap-3 mt-6'>
          <div className="flex flex-col gap-2  border-2 px-4 py-8 rounded-lg">
            <div>
              <img src={img2} className="rounded-lg h-[210px] w-full" />
            </div>
            <h4 className="font-bold text-center text-3xl">Extensive Coverage
            </h4>
            <p className="text-center text-lg">Serving all major cities and towns in Tamil Nadu, Pondicherry, and Bangalore.</p>
          </div>
          <div className="flex flex-col gap-2  border-2 px-4 py-8 rounded-lg">
            <div>
              <img src={img4} className="rounded-lg h-[210px] w-full" />
            </div>
            <h4 className="font-bold text-center text-3xl">Vehicle Variety</h4>
            <p className="text-center text-lg">Choose from a range of vehicles to suit your travel preferences, from economy to premium options.</p>
          </div>
          <div className="flex flex-col gap-2  border-2 px-4 py-8 rounded-lg">
            <div>
              <img src={img3} className="rounded-lg h-[210px] w-full" />
            </div>
            <h4 className="font-bold text-center text-3xl">Quick Booking Process</h4>
            <p className="text-center text-lg">Book your ride easily on our website, with immediate booking confirmation.</p>
          </div>



        </div>
      </div>

    </div>
  );
};

export default OurServices;
