import React from 'react'
import { BsFillChatQuoteFill } from "react-icons/bs";
import { BiSolidStar } from "react-icons/bi";

const TestimonialComponent = ({name,desc,route}) => {
  return (
    <div className='flex flex-col gap-3 rounded-md bg-primary-color p-3 mt-5 shadow-lg text-lg font-semibold transition ease-in-out duration-700 hover:bg-black hover:text-white'>
        <div className='flex '>
        <BsFillChatQuoteFill size={50}/>
        </div>
        <span className='font-bold'>{name}</span>
        <span className=''>{route}</span>
        <div className='flex  items-center'>
        <BiSolidStar /><BiSolidStar /><BiSolidStar /><BiSolidStar /><BiSolidStar />  (5.0)
        </div>
        <p>{desc}</p>
        </div>
  )
}

export default TestimonialComponent