import React from "react";
import { FaCar } from "react-icons/fa";
import { Link } from "react-router-dom";
import bg from "../assets/images/servbg.png";
import chennai from '../assets/places/Chennai.jpg';
import coimbatore from '../assets/places/Coimbatore.jpg';
import banglore from '../assets/places/Bangalore.jpg';
import salem from '../assets/places/salem.jpeg';
import madurai from '../assets/places/Madurai.jpg';
import pondicherry from '../assets/places/Pondicherry.jpg';
import vellore from '../assets/places/Vellore.jpeg';
import trichy from '../assets/places/Trichy.jpg';
import kumbakonam from '../assets/places/Kumbakonam.jpeg';

const PopularOutstations = () => {
  
  return (
    <div className="bg-secondary-color" >
      <div className=" container py-4 md:py-6 lg:py-8 mx-auto px-3 sm:px-4 md:px-8">
        <div className=" flex flex-col items-center gap-3">
          <h3 className="text-xl md:text-4xl font-bold mt-4 font-bold text-black relative inline-block header-underline pb-3 before:hidden sm:before:block">
          Explore Most Popular Droptaxi Routes
          </h3>
          <center className='lg:w-1/2 text-black mt-2'>At One way Taxi Cabs, we make your long-distance travel smooth, affordable, and convenient. Here are some of the most sought-after drop taxi routes that our customers love! Whether you're heading to the airport, a pilgrimage, or a business trip, we've got you covered with reliable one-way rides across Tamil Nadu, Pondicherry, and Bangalore.</center>

        </div>

        <div className=" grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-2 gap-x-3 gap-y-6 sm:gap-y-14 md:gap-y-16 mt-5 sm:mt-5 md:mt-7 lg:mt-10">
          <div className="flex flex-col items-center bg-white py-8 rounded-lg gap-y-5">
            <h3 className="text-[21px] md:text-[23px] font-bold  relative inline-block pb-3 before:hidden sm:before:block  ">
              CHENNAI DROP TAXI
            </h3>
            <div className="flex justify-center overflow-hidden rounded-xl">
            <img src={chennai} className=" w-[320px] rounded-xl transition ease-in-out duration-700 hover:scale-125 cursor-pointer"/>
            </div>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Chennai to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
            Chennai to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Chennai to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Chennai to Madurai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Chennai to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Chennai to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Chennai to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Chennai to Kumbakonam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Chennai to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Chennai to Neyveli Drop Taxi
              </li>
            </ul>
          </div>
          <div className="flex flex-col items-center bg-white py-8 rounded-lg gap-y-5">
            <h3 className="text-[21px] md:text-[23px] font-bold  relative inline-block pb-3 before:hidden sm:before:block">
              COIMBATORE DROP TAXI
            </h3>
            <div className="flex justify-center overflow-hidden rounded-xl">
            <img src={coimbatore} className=" w-[320px] rounded-xl transition_img hover:scale-125 cursor-pointer"
            />
            </div>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
        Coimbatore to Chennai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
            Coimbatore to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
    Coimbatore to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
        Coimbatore to Madurai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Coimbatore to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
        Coimbatore to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
      Coimbatore to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Coimbatore to Kumbakonam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Coimbatore to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Coimbatore to Neyveli Drop Taxi
              </li>
            </ul>
          </div>
          <div className="flex flex-col items-center bg-white py-8 rounded-lg gap-y-5">
            <h3 className="text-[21px] md:text-[23px] font-bold  relative inline-block pb-3 before:hidden sm:before:block">
              BANGALORE DROP TAXI
            </h3>
            <div className="flex justify-center overflow-hidden rounded-xl">
            <img src={banglore} className=" w-[320px] rounded-xl transition_img hover:scale-125 cursor-pointer"
            />
            </div>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Bangalore to Chennai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Bangalore to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Bangalore to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
        Bangalore to Madurai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Bangalore to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
        Bangalore to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
      Bangalore to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Bangalore to Kumbakonam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Bangalore to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Bangalore to Neyveli Drop Taxi
              </li>
            </ul>
          </div>
          <div className="flex flex-col items-center bg-white py-8 rounded-lg gap-y-5">
            <h3 className="text-[21px] md:text-[23px] font-bold  relative inline-block pb-3 before:hidden sm:before:block">
            SALEM DROP TAXI
            </h3>
            <div className="flex justify-center overflow-hidden rounded-xl">
            <img src={salem} className=" w-[320px] rounded-xl transition_img hover:scale-125 cursor-pointer"
            />
            </div>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Salem to Chennai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Salem to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Salem to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Salem to Madurai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Salem to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Salem to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Salem to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Salem to Kumbakonam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Salem to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Salem to Neyveli Drop Taxi
              </li>
            </ul>
          </div>
          <div className="flex flex-col items-center bg-white py-8 rounded-lg gap-y-5">
            <h3 className="text-[21px] md:text-[23px] font-bold  relative inline-block pb-3 before:hidden sm:before:block">
            MADURAI DROP TAXI
            </h3>
            <div className="flex justify-center overflow-hidden rounded-xl">
            <img src={madurai} className=" w-[320px] rounded-xl transition_img hover:scale-125 cursor-pointer"
            />
            </div>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Madurai to Chennai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Madurai to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
            Madurai to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Madurai to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Madurai to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Madurai to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Madurai to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Madurai to Kumbakonam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Madurai to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Madurai to Neyveli Drop Taxi
              </li>
            </ul>
          </div>
          <div className="flex flex-col items-center bg-white py-8 rounded-lg gap-y-5">
            <h3 className="text-[21px] md:text-[23px] font-bold  relative inline-block pb-3 before:hidden sm:before:block">
            PONDICHERRY DROP TAXI
            </h3>
            <div className="flex justify-center overflow-hidden rounded-xl">
            <img src={pondicherry} className=" w-[320px] rounded-xl transition_img hover:scale-125 cursor-pointer"
            />
            </div>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
          Pondicherry to Chennai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Pondicherry to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Pondicherry to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
      Pondicherry to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
          Pondicherry to Madurai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
          Pondicherry to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
        Pondicherry to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Pondicherry to Kumbakonam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
             Pondicherry to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
             Pondicherry to Neyveli Drop Taxi
              </li>
            </ul>
          </div>
          <div className="flex flex-col items-center bg-white py-8 rounded-lg gap-y-5">
            <h3 className="text-[21px] md:text-[23px] font-bold  relative inline-block pb-3 before:hidden sm:before:block">
            VELLORE DROP TAXI
            </h3>
            <div className="flex justify-center overflow-hidden rounded-xl">
            <img src={vellore} className=" w-[320px] rounded-xl transition_img hover:scale-125 cursor-pointer"
            />
            </div>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Vellore to Chennai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Vellore to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
            Vellore to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
          Vellore to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Vellore to Madurai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Vellore to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Vellore to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Vellore to Kumbakonam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Vellore to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Vellore to Neyveli Drop Taxi
              </li>
            </ul>
          </div>
          <div className="flex flex-col items-center bg-white py-8 rounded-lg gap-y-5">
            <h3 className="text-[21px] md:text-[23px] font-bold  relative inline-block pb-3 before:hidden sm:before:block">
            TRICHY DROP TAXI
            </h3>
            <div className="flex justify-center overflow-hidden rounded-xl">
            <img src={trichy} className=" w-[320px] rounded-xl transition_img hover:scale-125 cursor-pointer"
            />
            </div>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Trichy to Chennai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Trichy to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
            Trichy to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Trichy to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Trichy to Madurai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Trichy to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Trichy to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Trichy to Kumbakonam Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Trichy to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Trichy to Neyveli Drop Taxi
              </li>
            </ul>
          </div>
          <div className="flex flex-col items-center bg-white py-8 rounded-lg gap-y-5">
            <h3 className="text-[21px] md:text-[23px] font-bold relative inline-block pb-3 before:hidden sm:before:block">
            KUMBAKONAM DROP TAXI
            </h3>
            <div className="flex justify-center overflow-hidden rounded-xl">
            <img src={kumbakonam} className=" w-[320px] rounded-xl transition_img hover:scale-125 cursor-pointer"
            />
            </div>
            <ul className=" flex flex-col gap-[5px]">
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
          Kumbakonam to Chennai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Kumbakonam to Coimbatore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
              Kumbakonam to Bangalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Kumbakonam to Salem Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Kumbakonam to Madurai Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Kumbakonam to Pondicherry Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Kumbakonam to Vellore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Kumbakonam to Trichy Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Kumbakonam to Cuddalore Drop Taxi
              </li>
              <li className=" flex gap-x-2 text-lg items-center ">
                <FaCar className=" text-[20px] text-[#fab217]" />
                Kumbakonam to Neyveli Drop Taxi
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularOutstations;
