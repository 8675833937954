import React from "react";
import banner from "../assets/cars/bannerImg.png";
import Form from "./Form";
import { BiPhoneCall } from "react-icons/bi";
import { GoThumbsup } from "react-icons/go";

const BookingForm = () => {
  const divStyle = {
    backgroundImage: `url(${banner})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    width: "100%",
  };

  return (

    <div className=' bg-primary-color mt-5'>
      <div className="container  py-12 lg:px-40">
        <div className="  grid grid-cols-1 lg:grid-cols-2">
          <div className="flex flex-col lg:gap-3 justify-center ">
            <span class="flex gap-2 items-center bg-white rounded-full px-2 lg:px-4 py-2 lg:py-1 w-[330px] text-[14.5px] lg:text-[16px] lg:w-[342px] ">
              🚖Travel your way, pay for only the ride 🚖
            </span>
            <p className="text-[24.5px] font-bold font-serif p-2 md:p-0 leading-10">
              Affordable drop taxi service across Tamil Nadu,<br />Pondicherry, and Bangalore  with onewaytaxis.in!

            </p>

            {/* <a href="tel:+919940580167" className='px-4 py-2 rounded-3xl th-btn-reverse flex justify-center'>+91 9940580167</a> */}
            <a
              href="tel:+919940580167"
              className="   transition-all ease-linear  w-[200px] p-2 md:p-0"  >

              <span className=" flex bg-black rounded-md  gap-x-2 items-center  text-white p-2">
                <BiPhoneCall /> +91 99405 80167
              </span>
            </a>
            <div>

            </div>
          </div>
          <div>
            <img src={banner} />
          </div>
        </div>
        <div className="form_bg relative bg-fixed flex justify-center px-5" >
          <div className='bg-white w-full  pb-4 relative z-10 rounded ' id='book_taxi_form'>
            <Form />
          </div>
        </div>
      </div>
    </div>

    //   <div className="form_bg relative bg-fixed flex justify-center px-3" style={divStyle}>
    //   <div className='bg-white w-full md:w-[80%] lg:w-1/2  rounded-lg px-4  pb-4 relative z-10 mt-4 md:mt-8 mb-4 md:mb-8' id='book_taxi_form'>
    //     <Form />
    //   </div>
    // </div>
  );
};

export default BookingForm;
