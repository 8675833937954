import React from 'react'
import { GiHandOk } from "react-icons/gi";
import { MdLocationPin } from "react-icons/md";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";

import { RiTimerFlashFill } from "react-icons/ri";


const Chooseus = () => {

    return (
        <div id='about' className='container py-5 mx-auto px-4 md:px-8 lg:px-16'>
            <div className='flex flex-col items-center gap-3'>
                <h4 className=" text-2xl font-bold font-medium primary-color text-center">
                    About Us
                </h4>
                <div className=''>Welcome to onewaytaxis.in, your reliable drop taxi service provider across Tamil Nadu, Pondicherry, and Bangalore. Our mission is simple: to provide affordable, comfortable, and efficient rides to your chosen destination. We stand by our motto of "pay for only the ride," eliminating hidden fees or return charges. Whether you're heading to a bustling city or a quiet town, our skilled drivers and well-maintained vehicles ensure you have a smooth and safe journey.</div>
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-3 mt-5 gap-5'>
                <div className='flex flex-col items-center  gap-5 '>
                    <div className='border border-2 border-dashed border-black rounded-full p-3  '>
                        <MdLocationPin size={85} className='bg-primary-color rounded-full p-2 text-white' />
                    </div>
                    <div className='flex flex-col items-center'>
                        <p className='font-bold text-2xl'>1. Book Your Ride Anytime:</p>

                        <span className='text-center mt-2'>Call, WhatsApp, or Book Online – It's easy! Just provide your pick-up location, destination, and preferred time.
                            Available 24x7, so you can book rides at any time – day or night.
                        </span>
                    </div>
                </div>
                <div className='flex flex-col items-center gap-5 '>
                    <div className='border border-2 border-dashed border-black rounded-full p-3  '>
                        <IoMdCheckmarkCircleOutline size={85} className='bg-primary-color rounded-full p-2 text-white' />
                    </div>
                    <div className='flex flex-col items-center'>
                        <p className='font-bold text-2xl text-center'>2. Get Instant Confirmation Vehicle</p>

                        <span className='text-center mt-2'>Once your booking is placed, you’ll receive an instant confirmation via call or message with the driver’s details, including vehicle number, driver’s name, and contact.</span>
                    </div>
                </div>
                <div className='flex flex-col items-center  gap-5'>
                    <div className='border border-2 border-dashed border-black rounded-full p-3  '>
                        <RiTimerFlashFill size={85} className='bg-primary-color rounded-full p-2 text-white' />
                    </div>
                    <div className='flex flex-col items-center'>
                        <p className='font-bold text-2xl'>3. Driver Arrives On Time</p>

                        <span className='text-center mt-2'>Our professional driver will arrive at your location on time, ensuring a hassle-free pick-up. You’ll also receive a live update of the driver’s location so you can track your ride.</span>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default Chooseus